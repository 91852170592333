<template>
  <div class="container">
    <header>
      <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
      <div class="header_right" @click="$router.push('/Language')">
        <p class="txt">{{ $t('common').lang }}</p>
        <van-icon class="rotate90" name="play" size="14" color="#fff" />
      </div>
    </header>
    <!-- 页签 -->
    <div class="navTab">
      <div
        class="item"
        @click="activeNav = 0"
        :class="{ activeNav: activeNav === 0 }"
      >
        {{ $t('common').Phone.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="activeNav = 1"
        :class="{ activeNav: activeNav === 1 }"
      >
        {{ $t('common').Email.toUpperCase() }}
      </div>
    </div>
    <!-- 标题 -->
    <div class="title">
      <div class="title_Btn">{{ $t('register').btn.toUpperCase() }}</div>
    </div>
    <!-- 表单 -->
    <div class="content">
      <div class="form">
        <!-- 手机号注册 -->
        <div v-if="activeNav == 0" class="item">
          <div class="item_lable">{{ $t('common').Phone }}</div>
          <van-field v-model="PhoneNumber" type="tel" placeholder="">
            <template #left-icon>
              <i class="select_btn">
                <VueCountryIntl
                  schema="popover"
                  v-model="PhoneNumberCode"
                  :listZIndex="10000"
                  popoverClass="selectCountry"
                  :searchInputPlaceholder="$t('common').PleaseSerach"
                  @onChange="selectCountry"
                >
                  <div slot="reference" @click="selectCountry">
                    +{{ PhoneNumberCode }}
                  </div>
                </VueCountryIntl>
              </i>
            </template>
            <template #right-icon>
              <i
                class="send_btn"
                :style="countDownSec === 60 ? '' : 'background: #969696'"
                @click="handleClick"
              >
                <span v-if="countDownSec === 60">{{ tips }}</span>
                <span v-else>{{ countDownSec }} s</span></i
              >
            </template>
          </van-field>
        </div>
        <!-- 选择国家 -->
        <div v-if="activeNav == 1" class="item">
          <div class="item_lable">{{ $t('register').Country }}</div>
          <div class="select_country_btn">
            <VueCountryIntl
              schema="popover"
              v-model="PhoneNumberCode"
              :listZIndex="10000"
              popoverClass="selectCountry"
              :searchInputPlaceholder="$t('common').PleaseSerach"
              @onChange="selectCountry"
            >
              <div slot="reference" @click="selectCountry">
                {{ CountryName }}
              </div>
            </VueCountryIntl>
          </div>
        </div>
        <!-- 邮箱注册 -->
        <div v-if="activeNav == 1" class="item">
          <div class="item_lable">{{ $t('common').Email }}</div>
          <van-field v-model="Email" placeholder="">
            <template #right-icon>
              <i
                class="send_btn"
                :style="countDownSec === 60 ? '' : 'background: #969696'"
                @click="handleClick"
              >
                <span v-if="countDownSec === 60">{{ tips }}</span>
                <span v-else>{{ countDownSec }} s</span></i
              >
            </template>
          </van-field>
        </div>
        <!-- 验证码 -->
        <div class="item">
          <div class="item_lable">{{ $t('register').VerificationCode }}</div>
          <van-field v-model="Captcha" placeholder=""> </van-field>
        </div>
        <!-- 邀请码 -->
        <div class="item">
          <div class="item_lable">{{ $t('register').InvitationCode }}</div>
          <van-field v-model="InvitationCode" placeholder=""> </van-field>
        </div>
        <!-- 昵称 -->
        <div class="item">
          <div class="item_lable">{{ $t('common').NickName }}</div>
          <van-field v-model="NickName" placeholder=""> </van-field>
        </div>
        <!-- 密码 -->
        <div class="item">
          <div class="item_lable">{{ $t('common').Password }}</div>
          <van-field v-model="Password" type="password" placeholder="">
          </van-field>
        </div>
        <!-- 注册 -->
        <div class="item_btn" @click="handleSub">
          {{ $t('register').btn.toUpperCase() }}
        </div>
        <!-- 已有账号 -->
        <div class="c-c-btm">
          <div class="loginTxt" @click="$router.push({ name: 'Login' })">
            <div class="agree">
              {{ $t('register').haveAccount }}？<span>{{
                $t('register').haveLogin
              }}</span>
            </div>
          </div>
          <div>
            <!-- <span style="color: #4a30b7" @click="goServe">{{
              $t('login').server
            }}</span> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 验证码弹窗 -->
    <div
      :class="showImg ? 'dialogBox2' : 'dialogBox1'"
      @click="onlyClose($event)"
    >
      <div class="dialogBox" ref="msk">
        <div class="chooseInput">
          <van-field
            v-model="imageCodeTxt"
            :placeholder="$t('register').placeholder8"
          >
            <template #right-icon>
              <img
                class="imgCodeBox"
                :src="imageCodeTc"
                @click="getCaptchaImgTc()"
              />
            </template>
          </van-field>
        </div>
        <div class="chooseBtn" @click="closeImg">
          {{ $t('common').comfirm.toUpperCase() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
import { dataURLtoBlob, getRandomString, getLang } from '@/utils/validate'

export default {
  data() {
    return {
      showImg: false,
      PhoneNumberCode: '60', //手机区号
      CountryName: 'Malaysia', //国家名称
      CountryCode: 'my', //国家编码
      PhoneNumber: '', //手机号
      Email: '', //邮箱号
      Captcha: '', //验证码
      InvitationCode: '', //邀请码
      NickName: '', //昵称
      Password: '', //密码
      imageCode: '', //图形验证码
      imgCode: '', //图形验证码图片
      imgCodeToken: '', //图形验证码图片Token
      countDownSec: 60, //验证倒计时
      tips: this.$t('register').Send, //发送按钮
      activeNav: 0,
      timer: null,
      imageCodeTxt: '', //弹窗输入文字
      imageCodeTc: '', //弹窗的图片
      imgCodeTokenTc: '', //弹窗的token
      CaptchaToken: '' //短信验证码
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    if (this.$route.query.code) this.InvitationCode = this.$route.query.code
    // this.getPrivacyList()
    // this.getCaptchaImg()
  },
  methods: {
    onlyClose(ev) {
      // 点击遮罩关闭
      if (!this.$refs.msk.contains(ev.target)) {
        this.showImg = false
      }
    },
    selectCountry(val) {
      if (val.name) {
        this.CountryName = val.name
        this.CountryCode = val.iso2
      }
      this.$nextTick(() => {
        const searchInput = document.querySelector(
          '.selectCountry .search-input'
        )
        if (searchInput) {
          searchInput.style.width = '90%'
          searchInput.style.height = 'auto'
        }
      })
    },
    async goServe() {
      const res = await userApi.getClientConfig()
      this.$router.push({
        name: 'IframePage',
        query: { IframePage: res.Data.Service }
      })
      //  location.href = res.Data.Service
    },
    async closeImg() {
      if (!this.imageCodeTxt) {
        this.$toast({ message: this.$t('common').input })
        return
      }
      this.showImg = false
      if (this.activeNav == 0) {
        const pRes = await userApi.getCaptchaSms({
          PhoneNumber: this.PhoneNumber,
          PhoneCode: this.PhoneNumberCode,
          ImgCaptcha: this.imageCodeTxt,
          ImgToken: this.imgCodeTokenTc,
          PhoneNumberToken: getRandomString()
        })
        if (pRes.Success) this.$toast(this.$t('common').success)
        this.CaptchaToken = pRes.Data.Token
      }

      if (this.activeNav == 1) {
        const eRes = await userApi.getCaptchaEmail({
          Email: this.Email,
          EmailToken: getRandomString(),
          ImgCaptcha: this.imageCodeTxt,
          ImgToken: this.imgCodeTokenTc,
          EmailType: 'Register'
        })
        if (eRes.Success) this.$toast(this.$t('common').success)
        this.CaptchaToken = eRes.Data.Token
      }
      this.timer = setInterval(() => {
        this.countDownSec--
        if (this.countDownSec === 0) {
          this.countDownSec = 60
          this.tips = this.$t('register').Resend
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    async getCaptchaImgTc() {
      // 弹窗验证码
      this.imageCodeTxt = ''
      const res = await userApi.getCaptchaImg()
      this.imgCodeTokenTc = res.Data.Token
      var base64Img1 = res.Data.Data
      var blob1 = dataURLtoBlob(base64Img1)
      const imgUrl1 = URL.createObjectURL(blob1)
      this.imageCodeTc = imgUrl1
      if (this.imageCodeTc) {
        this.showImg = true
      }
    },
    async handleClick() {
      if (this.countDownSec != 60) return
      if (this.activeNav == 1) {
        // 邮箱注册
        if (this.timer || !this.checkEmail()) return
        this.getCaptchaImgTc()
      }
      if (this.activeNav == 0) {
        // 手机号注册
        if (this.timer || this.PhoneNumber == '') {
          if (!this.PhoneNumber) {
            this.$toast(this.$t('common').input)
          }
          return
        }
        this.getCaptchaImgTc()
      }
    },
    checkEmail() {
      // 邮箱验证
      if (!this.Email) {
        this.$toast(this.$t('common').input)
        return false
      }
      const reg = new RegExp(
        '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
      )
      if (!reg.test(this.Email.toLowerCase())) {
        this.$toast(this.$t('register').EmailError)
        return false
      } else {
        return true
      }
    },
    //提交注册
    async handleSub() {
      if (!this.checkForm()) return
      const form = {
        Password: this.Password,
        NickName: this.NickName,
        InvitationCode: this.InvitationCode,
        CountryCode: this.CountryCode
      }
      if (this.activeNav == 0) {
        this.$set(form, 'PhoneNumberCode', this.PhoneNumberCode)
        this.$set(form, 'PhoneNumber', this.PhoneNumber)
        this.$set(form, 'Type', 'PhoneNumber')
        this.$set(form, 'Captcha', this.Captcha)
        this.$set(form, 'CaptchaToken', this.CaptchaToken)
      }
      if (this.activeNav == 1) {
        this.$set(form, 'PhoneNumberCode', this.PhoneNumberCode)
        this.$set(form, 'Email', this.Email)
        this.$set(form, 'Type', 'Email')
        this.$set(form, 'Captcha', this.Captcha)
        this.$set(form, 'CaptchaToken', this.CaptchaToken)
      }
      await userApi.subAccountRegister(form)
      this.$toast(this.$t('common').success)
      this.handleLogin()
    },
    //登录
    async handleLogin() {
      const form = {
        Password: this.Password
      }
      if (this.activeNav == 0) {
        this.$set(form, 'PhoneNumber', this.PhoneNumber)
        this.$set(form, 'Type', 'PhoneNumber')
      }
      if (this.activeNav == 1) {
        this.$set(form, 'Email', this.Email)
        this.$set(form, 'Type', 'Email')
      }
      let res = await userApi.getAccountRefresh(form)
      if (res) {
        this.loginSuccess(res.Data.Token)
      }
    },
    //获取刷新token
    async loginSuccess(token) {
      let res = await userApi.getAccountToken(token)
      Token.set(res.Data.Token)
      setTimeout(() => {
        this.getAccountBase()
      }, 1000)
    },
    //获取用户信息
    async getAccountBase() {
      const aBase = await userApi.getAccountBase()
      localStorage.setItem('userInfo', JSON.stringify(aBase.Data))
      this.$router.push({ name: 'Home' })
    },
    checkForm() {
      if (!this.InvitationCode) {
        this.$toast(this.$t('common').input)
        return false
      }
      if (!this.NickName) {
        this.$toast(this.$t('common').input)
        return false
      }
      if (!this.Captcha) {
        this.$toast(this.$t('common').input)
        return false
      }
      if (!this.NickName) {
        this.$toast(this.$t('common').input)
        return false
      }
      if (this.Password.length < 6) {
        this.$toast(this.$t('common').passwordCheck)
        return false
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background: #ffffff;
  padding-bottom: 30px;
  header {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    height: 291px;
    background-image: url(../../assets/newImg/regedit_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 20px 0 20px;
    .header_right {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      right: 20px;
      .txt {
        margin: 0 10px;
      }
      .img {
        margin-left: 3px;
        margin-bottom: 2px;
      }
    }
  }
  .navTab {
    display: flex;
    justify-content: space-between;
    margin: -256px 15px 15px 15px;
    height: 45px;
    background-image: url(../../assets/newImg/regedit_tab.png);
    background-size: 100% 100%;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 9px;
      color: #ffffff;
      margin: 7px 30px 10px 30px;
    }
    .activeNav {
      color: #9a5300;
      background-image: url(../../assets/newImg/active_btn.png);
      background-size: 100% 100%;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    .title_Btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 40px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      box-shadow: 0px 1px 3px 0px rgba(173, 61, 0, 0.75);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 18px;
      color: #ffffff;
      line-height: 12px;
    }
  }

  .content {
    margin: -20px 20px 0 20px;
    .form {
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px rgba(73, 47, 182, 0.75);
      border-radius: 15px;
      padding: 40px 20px;
      .item_lable {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 12px;
        color: #333333;
        margin-bottom: 10px;
      }
      .item_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        height: 44px;
        background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
        border-radius: 22px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 18px;
        color: #ffffff;
      }
      .select_btn {
        margin-right: 10px;
      }
      .select_country_btn {
        height: 38px;
        border-radius: 19px;
        border: 1px solid #999999;
        padding: 0 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .send_btn {
        margin-right: -13px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 24px;
        background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
        border-radius: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 12px;
        color: #ffffff;
      }
      .van-button {
        height: 37px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }
  }

  ::v-deep .van-field__value {
    overflow: visible;
    display: grid;
  }

  ::v-deep .van-button__content {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
  }

  .van-cell {
    margin-bottom: 20px;
    height: 38px;
    border-radius: 19px;
    border: 0.5px solid #999999;
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #333333;
  }

  ::v-deep .van-field__right-icon {
    margin: -10px 8px;
    padding: 0px;
    line-height: normal;
  }

  .imgCodeBox {
    display: flex;
    align-items: center;
    width: 75px;
    height: 32px;
  }

  .loginTxt {
    display: flex;
    align-items: center;
  }

  .agree {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      color: #4a30b7;
    }
  }

  .c-c-btm {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
    justify-content: center;
  }

  .van-checkbox {
    margin: 15px 0;
    font-size: 12px;
  }

  ::v-deep .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #2868d5;
    border-color: #2868d5;
  }

  .login_Title {
    margin: 15px 0px 41px;
    font-size: 29px;
    font-weight: 500;
    color: #515151;
  }
}

// 弹窗
.dialogBox2 {
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.dialogBox {
  position: relative;
  width: 300px;
  height: 120px;
  background: #ffffff;
  position: fixed;
  top: 200px;
  left: 40px;
  right: 40px;
  border-radius: 15px;
  font-size: 12px;
  color: #2868d5;
}

.dialogBox1 {
  display: none;
}

.chooseInput {
  padding: 25px 10px 5px 10px;
  width: 300px;
  font-size: 16px;
  line-height: 30px;
  color: #515151;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.chooseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
  border-radius: 22px;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 18px;
  color: #ffffff;
}

.rotate90 {
  transform: rotate(90deg);
}
</style>
